<template>
  <ReservationDetails forStayConfirmation />
</template>

<script>
import ReservationDetails from '@/views/Booking/ReservationDetails'

export default {
  name: 'Confirmation',
  components: {
    ReservationDetails
  }
}
</script>
