<template>
  <div class="px-3 pb-4">
    <h2 class="text-medium --c-medium-gray text-left font-weight-bold pt-3 pb-3 ">
      {{ $t("text.selectCancelStayDates") }}
    </h2>
    <div class="px-10 text-center pb-10">
      <v-checkbox
        v-for="stayDate in stayDates"
        :key="stayDate"
        v-model="cancelStayDates"
        :label="jaLongDate(stayDate)"
        :value="stayDate"
        dense
      ></v-checkbox>
    </div>

    <v-btn
      @click="confirm"
      :disabled="cancelStayDates.length === 0"
      rounded
      height="57"
      width="100%"
      max-width="262"
      class="text-medium font-weight-bold mb-3 bg-primary --c-white"
    >
      {{ $t("text.cancelSomeDates") }}
    </v-btn>

    <v-btn
      elevation="0"
      rounded
      @click="$listeners.back"
      height="57"
      width="100%"
      max-width="262"
      class="text-medium font-weight-bold mb-3 bg-white --prm]"
    >
      {{ $t('reservationAndUsageHistory.return') }}
    </v-btn>
  </div>
</template>

<script>
import { jaLongDate } from '@/utils/get-date'

export default {
  props: {
    stayDates: Array
  },
  data () {
    return {
      cancelStayDates: []
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm', { cancelStayDates: this.cancelStayDates })
    },
    jaLongDate,

  }
}
</script>
