<template>
  <div class="bg-smoke rounded mb-3">
    <Details
      :left="item.guest"
      :right="guestName"
      :aClass="'font-weight-bold --medium-gray col-8 pb-2'"
      :divider="true"
    />
<!--    <v-divider class="mx-4"/>-->
    <Details
      :left="item.schedule"
      :right="schedule"
      :aClass="'--medium-gray col-8'"
    />
  </div>
</template>

<script>
import Details from './index'

export default {
  name: 'GuestCard',
  components: {
    Details
  },
  props: {
    index: {
      Number,
      default: 1
    },
    guestName: {
      String
    },
    schedule: {
      String
    }
  },
  data () {
    return {
      item: {
        id: '',
        guest: this.$t('reservationAndUsageHistory.guest') + this.index,
        schedule: this.$t('reservationAndUsageHistory.accommodationSchedule')
      }
    }
  }
}
</script>
