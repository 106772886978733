<template>
  <v-dialog
    v-model="isVisible"
    width="378"
    content-class="bg-w-silver text-center"
  >
    <div class="text-right">
      <button @click.stop="isVisible=false" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>
    <slot/>
    <slot name="buttons" />
    <v-btn
      elevation="0"
      rounded
      width="262"
      height="57"
      class="bg-silver mt-3 --prm font-weight-bold"
      :to="'/'"
    >
      {{ $t('buttons.returnToTop') }}
    </v-btn>

    <div class="pa-5">

    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'ReturnTopPage',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
