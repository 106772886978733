<template>
  <CommonModal
    :visible="isVisible"
    :is-persistent="true"
    @close="$emit('close')"
  >
    <div v-if="isVisible" class="px-5 pb-4">
      <AskDaysV2
        v-if="needsToAskDays"
        :stayDates="stayDates"
        @back="close"
        @confirm="setCancelStayDates"
      />
      <CancelForm
        v-else
        :cancelStayDates="cancelStayDates"
        :allCancel="isConsequentlyAllCancel"
        @back="closeOrSelectDays"
        @complete="complete"
      />
      <ReturnTopPage :visible="completed" @close="completed=false">
        <h2 class="text-medium --medium-gray font-weight-bold pt-3 pb-3">
          {{ $t('text.canceledMyReservation') }}
        </h2>

        <v-container class="text-left px-10 py-6" v-if="totalRefundPoint">
          <v-row>
            <v-col class="text-heading font-weight-bold --prm px-0 py-0">
              {{ pointName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="--prm pa-0 py-0">
              <div class="text-huge  d-flex ">
                <span class="font-weight-bold">{{ totalRefundPoint | toThousands }}</span>
                <div
                  class="text-medium font-weight-normal d-flex flex-column justify-end pa-auto pb-1"
                >
                  {{ $t('unit.point') }}
                </div>
              </div>
            </v-col>
            <v-col class="text-regular d-flex flex-column justify-end pa-0 pb-1">
              <div class="text-medium font-weight-bold --medium-gray">
                {{ $t('text.hasBeenReturned') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </ReturnTopPage>
    </div>
  </CommonModal>
</template>

<script>
import { getStayDateRangeArray } from '@/utils/get-date'
import { selectedBookingComputed } from '../../selected-booking.mixin'
import AskDaysV2 from './AskDaysV2'
import CancelForm from './CancelForm'
import ReturnTopPage from '../ReturnTopPage.vue'
import CommonModal from '@/components/Modal/Common'

export default {
  components: { AskDaysV2, CancelForm, ReturnTopPage, CommonModal },
  mixins: [selectedBookingComputed],
  name: 'ReservationCancellation1',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    allCancel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      daysToCancel: null,
      completed: false,
      totalRefundPoint: null,
      partCancelStayDates: [],
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.close()
        }
      }
    },
    stayDates () {
      return getStayDateRangeArray(this.booking.checkInDate, this.booking.checkOutDate)
    },
    cancelStayDates () {
      if (this.allCancel) {
        return this.stayDates
      } else {
        return this.partCancelStayDates
      }
    },
    needsToAskDays () {
      return !this.cancelStayDates.length
    },
    isConsequentlyAllCancel () {
      return this.cancelStayDates.length === this.stayDates.length
    }
  },
  methods: {
    close () {
      this.daysToCancel = null
      this.$emit('close')
    },
    setCancelStayDates ({ cancelStayDates }) {
      this.partCancelStayDates = cancelStayDates
    },
    closeOrSelectDays () {
      if (this.allCancel) this.close()
      else {
        this.partCancelStayDates = []
      }
    },
    complete ({ totalRefundPoint }) {
      this.totalRefundPoint = totalRefundPoint
      this.completed = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .f-w-100 {
    font-weight: 300;
  }

</style>
