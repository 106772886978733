<template>
  <div v-if="booking">
    <Breadcrumbs>
      <router-link to="/home/booking">
        {{ $t("reservationAndUsageHistory.reservationAndUsageHistory") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5 pb-15 pt-5">
      <UsageHistoryItem
        :id="booking.id"
        :urlImg="facility.mainImageUrl"
        :title="facility.name"
        :checkInDate="booking.checkInDate"
        :checkOutDate="booking.checkOutDate"
        :roomTypeName="booking.room.roomType.name"
        :isActive="true"
        :cancelledAt="cancelledAt"
      />

      <GuestCard
        v-for="(guest, index) in booking.guests"
        :key="guest.id"
        :index="index + 1"
        :guestName="guest.name"
        :schedule="getSchedule(guest)"
      />

      <BookingPlan
        v-if="booking.bookingPlanAnswer"
        :bookingPlanAnswer="booking.bookingPlanAnswer"
        :planName="booking.bookingPlan ? booking.bookingPlan.title : null"
      />

      <RentalList
        v-for="(itemGroup, ind) in getRentalItemGroups(booking.rentalItems)"
        :key="`rental-${ind}`"
        :rental="itemGroup.list"
        :schedule="itemGroup.schedule"
        :index="ind"
      />
      <div
        v-if="hasRentalItems && booking.rentalItems.length === 0"
        class="bg-smoke rounded mb-3"
      >
        <Details
          left="貸出品要否"
          right="不要"
          :aClass="'font-weight-bold --c-medium-gray text-right col-6'"
        />
      </div>
      <Parking :number="parkingCount" v-if="hasParkings" />
      <!-- removed, see uat member-143 -->
      <!-- <Details
        :left="$t('text.optionalCleaning')"
        :right="hasOptionalCleaningFee ? $t('common.existence') : $t('common.none')"
        :aClass="'font-weight-bold text-right --dark'"
      /> -->

      <PetInfo v-if="acceptsPet && !this.showOldPetInfo" :petInfo="booking.petInfo" :catAllowed="facility.catAllowed">
      </PetInfo>

      <template v-if="this.forStayConfirmation">
        <div class="text-center text-medium --w-bold mt-5 --prm">
          {{ $t('text.ifNoChanges') }}
          <br />
          {{ $t('text.pressThisButton') }}
        </div>
        <v-btn
          elevation="0"
          rounded
          block
          height="66"
          @click="confirmStay"
          class="text-medium my-5 --prm-light"
          color="white"
        >
          {{ $t('text.completeUsageConfirm') }}
        </v-btn>
        <div class="text-center">
          <a
            class="real-anchor text-decoration-underline text-medium"
            :href="facility.facilityHpUrl"
            target="_blank"
          >
            {{ $t('text.openFacInfo') }}
          </a>
        </div>

        <div
          class="text-center text-medium --w-bold --prm mt-6 pt-4"
          v-if="btns.length"
        >
          {{ $t('text.wishChangeOptions') }}
        </div>
      </template>

      <template v-if="active">
        <div v-if="btns.length" class="mt-10">
          <v-btn
            elevation="0"
            rounded
            block
            x-large
            v-for="(btn, ind) in btns"
            :key="`btn-${ind}`"
            @click="btn.click"
            class="text-medium mb-3"
            :class="btn.class"
            :color="btn.color"
          >
            <span class="w-100">{{ btn.text }} <br/> <span class="text-normal">{{ btn.description }}</span></span>
            <IconChevronRightCircle :color="btn.whiteIcon ? 'white' : 'var(--color__primary-light)'" width="23" class="mr-2"/>
          </v-btn>
        </div>
      </template>
      <div v-else>
        <v-btn
          elevation="0"
          rounded
          block
          text
          height="66"
          style="display:block"
          class="text-medium --white --w-bold mt-7 mb-3"
          color="btn-grad--orange"
        >
          <div class="d-flex">
            <span class="mx-auto">{{ $t('buttons.showReceipts') }}</span>
            <IconRight class="mr-2" />
          </div>
        </v-btn>
      </div>
      <div v-if="noPreCheckIn" class="pa-2">{{ $t('text.noPreCheckIn') }}</div>
    </div>
    <ReservationCancelDialog
      v-if="cancelDialog"
      :visible="true"
      :allCancel="allCancel"
      @close="cancelDialog = false"
    />
    <ReturnTopPage :visible="stayConfirmed" @close="stayConfirmed = false">
      <div class="pl-13 pb-10 text-title text-left --bg font-weight-bold">
        {{ $t('text.haveConfirmationOfUse') }}
      </div>
    </ReturnTopPage>
  </div>
</template>

<script>
import ReservationCancelDialog from './../components/ReservationCancelDialog'
import Breadcrumbs from '@/components/Breadcrumbs'
import UsageHistoryItem from '@/components/UsageHistoryItem'
import Details from '@/components/Details'
import GuestCard from '@/components/Details/GuestCard'
import RentalList from '@/components/Details/RentalList'
import PetInfo from '@/components/Details/PetInfo'
import Parking from '@/components/Details/Parking'
import IconRight from '@/components/icons/IconRight'
import {
  addDays,
  jaLongDateRange
} from '@/utils/get-date'
import ReturnTopPage from '../components/ReturnTopPage.vue'
import selectedBookingWithParkingAndRentalMixin from '../selectedBookingWithParkingAndRentalMixin'
import IconChevronRightCircle from '@/components/icons/IconChevronRightCircle'
import { BookingTypeIds } from '@/constants/booking-type'
import BookingPlan from '@/components/Details/BookingPlan.vue'

export default {
  name: 'ReservationDetails',
  mixins: [selectedBookingWithParkingAndRentalMixin],
  components: {
    Breadcrumbs,
    ReservationCancelDialog,
    Details,
    RentalList,
    UsageHistoryItem,
    GuestCard,
    ReturnTopPage,
    Parking,
    IconRight,
    IconChevronRightCircle,
    BookingPlan,
    PetInfo
  },
  props: {
    forStayConfirmation: Boolean
  },
  methods: {
    handleClick (link, modal) {
      if (link) {
        this.$router.push(link)
      } else {
        if (modal === 1) {
          this.reservationCancellation.first = true
        }
        if (modal === 2) {
          this.reservationCancellation.cash = true
        }
      }
    },
    getSchedule (guest) {
      const {
        stayDateRange
      } = guest
      if (!stayDateRange) return this.$t('common.noOverNight')
      return jaLongDateRange(stayDateRange.fromDate, addDays(stayDateRange.toDate, 1))
    },
    getRentalItemGroups (rentalItems) {
      const dateToItems = {}
      rentalItems.forEach((rentalItem) => {
        const {
          fromDate,
          toDate,
          quantity,
          rentalItem: {
            rentalItemDef: {
              name
            }
          }
        } = rentalItem
        const key = `${fromDate}-${toDate}`

        if (!dateToItems[key]) {
          dateToItems[key] = {
            fromDate,
            toDate,
            items: []
          }
        }
        dateToItems[key].items.push({
          name,
          quantity
        })
      })

      return Object.values(dateToItems).map(group => {
        return {
          list: group.items.map(item => `${item.name}×${item.quantity}${this.$t('common.individual')}`).join(', '),
          schedule: jaLongDateRange(group.fromDate, group.toDate)
        }
      })
    },
    async confirmStay () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('confirmStay', {
            id: this.id
          })
        })
        this.stayConfirmed = true
      })
    }
  },
  data () {
    return {
      active: true,
      stayConfirmed: false,
      cancelDialog: false,
      allCancel: false
    }
  },
  computed: {
    bookingTypeId () {
      return this.booking?.bookingTypeId
    },
    noPreCheckIn () {
      return this.bookingTypeId === BookingTypeIds.POINT
    },
    btns () {
      const buttons = []
      if (this.canModify) {
        if (!this.forStayConfirmation) {
          // precheckin is disabled. see TO2020-567
          // buttons.push({
          //   text: this.$t('text.enterPreCheckInInfo'),
          //   class: '--white',
          //   color: 'btn-grad--prm',
          //   whiteIcon: true,
          //   click: () => {
          //     this.$router.push({
          //       name: 'home-booking-reservation-details-detail-pre-check-in-info',
          //       params: { id: this.id.toString() }
          //     })
          //   }
          // })
          buttons.push({
            text: this.$t('menu.main.items.reservationChange'),
            class: '--white',
            color: 'btn-grad--orange',
            whiteIcon: true,
            click: () => {
              this.$router.push({
                name: 'home-booking-reservation-details-detail-reservation-change',
                params: { id: this.id.toString() }
              })
            }
          })
        }
        buttons.push({
          text: this.$t('buttons.openFacilityInformation'),
          class: '--prm-light',
          color: 'white',
          disabled: !this.facility?.facilityHpUrl,
          click: () => {
            window.open(this.facility?.facilityHpUrl)
          }
        })
        if (this.canModify) {
          if (!this.isFixedRightBooking) {
            buttons.push({
              text: this.$t('reservationAndUsageHistory.moveOfAccommodationDate'),
              class: '--prm-light',
              color: 'white',
              click: async () => {
                await this.$router.push({
                  name: 'home-booking-reservation-details-detail-reservation-change-accommodation-date-move',
                  params: { id: this.id.toString() }
                })
              }
            })
          }
        }
        buttons.push({
          text: this.$t('text.cancelAllReservation'),
          class: '--prm-light',
          color: 'white',
          click: () => {
            this.allCancel = true
            this.cancelDialog = true
          }
        })
        // buttons.push({
        //   text: this.$t('text.partialScheduleCancellation'),
        //   class: '--prm-light',
        //   color: 'white',
        //   click: () => {
        //     this.allCancel = false
        //     this.cancelDialog = true
        //   }
        // })
      }
      // don't show the invoice, see uat member-141 for detail
      // this.booking?.finalizedInvoices.forEach((invoice, ind) => {
      //   buttons.push({
      //     text: this.$t('common.receipt') + (ind + 1) + this.$t('common.display'),
      //     click: () => {
      //       window.open(invoice.pdfFileUrl)
      //     }
      //   })
      // })

      return buttons
    }
  }
}
</script>
