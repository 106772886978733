<template>
  <v-text-field
    ref="input"
    type="tel"
    :id="id"
    :value="formatted"
    @input="v => input(v)"
    :rules="rules"
    v-bind="$attrs"
    :solo="solo"
    :flat="flat"
    :outlined="outlined"
    :hide-details="hideDetails"
    :disabled="disabled"
    autocomplete="off"
  />
</template>

<script>
import { toThousands } from '@/filters'

export default {
  name: 'NumberFormattedInput',
  props: {
    value: Number,
    required: Boolean,
    id: {
      type: String,
      required: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    solo: Boolean,
    flat: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    disabled: Boolean
  },
  computed: {
    formatted () {
      return this.getEmitValue(this.localValue)
    }
  },
  data () {
    return {
      localValue: this.value,
      rules: [
        v => !!v.length || this.$t('rules.isRequired'),
        ...((typeof this.min) === 'number' ? [v => this.getNumberValue(v) >= this.min || `${this.min}以上の値をご入力下さい`] : []),
        ...((typeof this.max) === 'number' ? [v => this.getNumberValue(v) <= this.max || `${this.max}以下の値をご入力下さい`] : [])
      ]
    }
  },
  watch: {
    value (val) {
      this.localValue = val
    }
  },
  methods: {
    getEmitValue (value) {
      return toThousands(this.getNumberValue(value))
    },
    getNumberValue (value) {
      if (!value) return 0
      return Number(value.toString().replace(/[^\d-]/g, ''))
    },
    input (value) {
      const num = this.getNumberValue(value)
      this.localValue = toThousands(num)
      this.$emit('input', num)
    }
  }
}
</script>
