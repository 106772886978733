<template>
  <div class="px-0 pb-4">
    <h2 class="text-title --prm text-left pt-3 pb-3">{{ $t('text.areYouSureCancelReservation') }}？</h2>
    <template v-if="noPenalty">
      <div v-if="totalRefundPoint">
        <div class="text-normal font-weight-bold --prm">{{ pointName }}</div>
        <div>
          <span class="text-title font-weight-bold --prm text-right">{{ totalRefundPoint | toThousands }}</span>
          <span class="text-normal font-weight-bold --prm text-right">{{ priceUnit }}</span>
          {{ $t('text.returnedToYou') }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-normal  --gray text-left pb-11">
        <span v-if="isPointBasedProduct">{{ $t('text.pointsWillBeRefunded') }}</span>
        <span v-else>{{ $t('text.cardAsCancellationFees') }}</span>
      </div>

      <!--Total cancellation fee START-->
      <div class="d-flex justify-space-between align-center --prm">
        <div class="text-normal font-weight-bold --prm text-left pt-3 pb-3">
          <span v-if="isPointBasedProduct">{{ $t('text.totalCancellation') }}<br>{{ pointName }}</span>
          <span v-else>{{ $t('text.totalCancelationFee') }}</span>
        </div>
        <div class="">
          <span class="text-title font-weight-bold --prm text-right">{{ totalPenalty | toThousands }}</span>
          <span class="text-normal font-weight-bold --prm text-right">{{ priceUnit }}</span>
        </div>
      </div>
      <v-divider></v-divider>
      <!--Total cancellation fee END-->

      <v-divider class="pt-1"></v-divider>

      <!--Breakdown of cancellation fee START-->

      <div class="text-normal --bg text-left font-weight-bold f-w-100 pt-7 pl-1 pb-5" >
        {{ $t('text.cancellationFeeBreakdown') }}
      </div>
      <v-container v-for="(day, ind) in penaltiesSorted" :key="ind" class="mb-6">
        <v-row>
          <v-divider class="bg-dark-silver"></v-divider>
        </v-row>
        <v-row>
          <v-col class="--bg text-left px-0">
            {{ $t('text.reservationDateAndTime') }}
          </v-col>
          <v-col class="font-weight-bold --prm pl-0">
            {{ day.stayDate | jaLongDateSlash }}
          </v-col>
        </v-row>
        <v-row>
          <v-divider class="bg-dark-silver"></v-divider>
        </v-row>
        <v-row>
          <v-col class="--bg text-left px-0 col-7">
            {{ $t("common.cancellationPolicy") }}
          </v-col>
          <v-col class="text-left font-weight-bold col-5 --prm px-0">
            {{ day.penaltyRate | percent }}
          </v-col>
        </v-row>
        <v-row>
          <v-divider class="bg-dark-silver"></v-divider>
        </v-row>
        <v-row>
          <v-col class="--bg text-left pl-0 col-7">
            {{ $t('text.cancellationCharge') }}
          </v-col>
          <v-col class="text-left col-5 font-weight-bold --prm pl-0">
            {{ getCancelPenalty(day) | toThousands }}{{ priceUnitShort }}
          </v-col>
        </v-row>
        <v-row>
          <v-divider class="bg-dark-silver"></v-divider>
        </v-row>
      </v-container>

      <!--Breakdown of cancellation fee END-->
      <!--Total cancellation fee END-->

      <template v-if="isPointBasedProduct">
        <div
          v-for="(refund, ind) in refunds"
          :key="`refund-${ind}`"
          class="d-flex justify-space-between align-center --prm"
        >
          <div class="text-normal text-left font-weight-bold --prm pt-3 pb-3">
            {{ $t('common.restitution') }}{{refund.year}}{{ $t('contract.year.year1') }}<br>{{pointName}}
          </div>
          <div class="text-right">
            <span class="text-title font-weight-bold">{{refund.amount}}</span>
            <span class="text-x-small font-weight-bold">{{ $t('unit.point') }}</span>
          </div>
        </div>
        <v-divider class="bg-dark-silver"></v-divider>
      </template>
      <template v-else-if="canUseTvp && allCancel">
        <div>
          <!--Use Vacations Points for payment START-->
          <v-container>
            <v-row>
              <v-col class="--prm col-1 pa-0 text-left">
                <v-checkbox
                  id="useTvpCheckbox"
                  v-model="useTvp"
                >
                </v-checkbox>
              </v-col>
              <v-col class="d-flex flex-column justify-center col-11">
                <label for="useTvpCheckbox" class="--gg text-left">{{ $t('text.useVacationsPointsForPayment') }}</label>
              </v-col>
            </v-row>
          </v-container>
          <!--Use Vacations Points for payment END-->

          <!--Number of points used START-->
          <div class="--gg text-left pt-5 pb-2" >{{ $t('text.numberOfPointsUsed') }}</div>
          <v-container>
            <v-row>
              <v-col class="--prm  pa-0 py-0 text-left">
                <NumberFormattedInput
                  :disabled="!useTvp"
                  v-model="useTvpAmount"
                  :min="0"
                  :max="remainingTvp"
                  solo
                  flat
                  outlined
                />
              </v-col>
              <v-col class="text-regular d-flex flex-column justify-end pa-0 pb-8">
                <div class="--gg pl-3 text-left">{{ $t('unit.point') }}</div>
              </v-col>
            </v-row>
          </v-container>
          <div class="--prm font-weight-bold text-left pt-2 pb-2">
            ({{ $t('text.numberOfPointsPossessed') }}:{{ remainingTvp | toThousands }}pt)
            <br>1pt1円で利用することができます
          </div>
          <!--Number of points used END-->
        </div>
      </template>

      <template v-if="mustSelectPaymentMethod">
        <!-- if booking was created by a submember,
          and the current user is the main user,
          they may choose to pay for the cancellation fee using
          the registered credit card
          see https://kujira.backlog.com/view/TO2020-552#comment-239089163
        -->
        <div class="--bg text-left f-w-100 pt-7 pl-1 pb-2">
          お支払い方法を選択してください。<br>
          （TVP利用を差し引いた金額へのお支払い方法を選択してください）
        </div>
        <v-radio-group v-model="paymentMethod" :rules="[$rules.required]">
          <v-radio value="RegisteredCC" label="登録カード" :ripple="false" />
          <v-radio value="Invoice" label="宿泊代表者への請求書による振込" :ripple="false" />
        </v-radio-group>
      </template>
    </template>

    <div v-if="askPrePaymentAdjustment" class="mt-4">
      <TVPPrePaymentChangeFormForCancel
        inDialog
        :valid.sync="newPrePaymentValid"
        :newAccommodationFee="accommodationFeeAfterCancel"
        :total-penalty="totalPenalty"
        v-model="prePaymentAndCancelTvp"
      />
    </div>

    <!--Enter your login password START-->
    <div class="--bg text-left f-w-100 pt-7 pl-1 pb-2">
      {{ $t('common.pleaseEnterYourLoginPassword') }}
    </div>

    <!-- Needs to not have a suggestion for input -->
    <input name="login" style="visibility: hidden" />
    <PasswordInput
      v-model="password"
      class="mb-6 rounded-lg"
      hide-details
    />
    <!--Enter your login password END-->

    <v-btn
      elevation="0"
      :disabled="!ready"
      rounded
      height="57"
      width="262"
      class="bg-primary --white text-medium font-weight-bold mb-3"
      @click="confirm"
    >
      {{ $t('menu.main.items.cancelReservation') }}
    </v-btn>
    <v-btn
      elevation="0"
      rounded
      height="57"
      width="262"
      class="bg-white --prm text-medium font-weight-bold mb-3"
      @click="$listeners.back">
      {{ $t('buttons.return') }}
    </v-btn>
  </div>
</template>

<script>
import { isPointBasedProduct } from '@/constants/product'
import { selectedBookingComputed } from '../../selected-booking.mixin'
import NumberFormattedInput from '@/components/Inputs/NumberFormattedInput'
import TVPPrePaymentChangeFormForCancel from '@/views/Booking/ReservationDetails/ReservationChange/TVPPrePaymentChange/TVPPrePaymentChangeFormForCancel/index.vue'
import PasswordInput from '../../../../components/Inputs/PasswordInput.vue'

export default {
  mixins: [selectedBookingComputed],
  components: { NumberFormattedInput, TVPPrePaymentChangeFormForCancel, PasswordInput },
  props: {
    cancelStayDates: {
      type: Array,
      required: true
    },
    allCancel: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    await this.calculate()
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('loadRemainingPoints')
      })
    })
  },
  data () {
    return {
      useTvp: false,
      useTvpAmount: 0,
      password: null,
      penalties: [],
      contractPoints: [],
      paymentMethod: null,
      prePaymentAndCancelTvp: {},
      newPrePaymentValid: false
    }
  },
  computed: {
    noPenalty () {
      return this.totalPenalty === 0
    },
    totalRefundPoint () {
      return this.refunds.reduce((sum, refund) => sum + refund.amount, 0)
    },
    totalPenalty () {
      return this.penalties.reduce((sum, p) => sum + this.getCancelPenalty(p), 0)
    },
    finalPenaltyToPay () {
      return this.totalPenalty - this.useTvpAmount
    },
    mustSelectPaymentMethod () {
      return this.$isMainUser() && this.isSubMemberCreated && this.finalPenaltyToPay > 0
    },
    refunds () {
      const refundByDate = this.penaltiesSorted.map(penalty => {
        return {
          stayDate: penalty.stayDate,
          amount: (
            (penalty.spPoint ?? 0) - (penalty.penaltySpPoint ?? 0) +
            (penalty.fpPoint ?? 0) - (penalty.penaltyFpPoint ?? 0)
          )
        }
      })
      const refundByYear = {}
      refundByDate.forEach(refund => {
        const contractPoint = this.contractPoints
          .find(cp => cp.validFrom <= refund.stayDate && cp.validTo >= refund.stayDate)
        let baseDate
        if (contractPoint) {
          baseDate = contractPoint.validFrom
        } else {
          baseDate = refund.stayDate
        }
        const year = new Date(baseDate).getFullYear()
        if (!refundByYear[year]) {
          refundByYear[year] = { contractPoint, amount: 0 }
        }
        refundByYear[year].amount += refund.amount
      })
      return Object.keys(refundByYear).sort().map(year => {
        return {
          year,
          amount: refundByYear[year].amount
        }
      })
    },
    remainingTvp () {
      if (this.allCancel) {
        // all cancel will refund all the prepaid point, so the user can use all the remaining point
        return this.remainingPrePayment + this.$store.getters.remainingTvp
      } else {
        return this.$store.getters.remainingTvp
      }
    },
    penaltiesSorted () {
      return this.penalties.slice().sort((a, b) => a.stayDate.localeCompare(b.stayDate))
    },
    ready () {
      return !!this.password &&
        (!this.useTvp || this.useTvpAmount >= 0) &&
        !!this.penalties?.length &&
        (!this.mustSelectPaymentMethod || this.paymentMethod) &&
        (!this.askPrePaymentAdjustment || this.newPrePaymentValid)
    },
    askPrePaymentAdjustment () {
      return !this.allCancel && this.canUseTvp
    },
    accommodationFeeToBeCancelled () {
      return this.penalties.reduce((sum, p) => sum + p.price, 0)
    },
    accommodationFeeAfterCancel () {
      return this.currentAccommodationFee.jpy - this.accommodationFeeToBeCancelled
    }
  },
  watch: {
    useTvp (val) {
      if (!val) {
        // reset the input of the amount
        this.useTvpAmount = null
      }
    },
    cancelStayDates: {
      async handler () {
        await this.calculate()
      }
    },
    contract: {
      immediate: true,
      async handler (contract) {
        this.contractPoints = []
        if (contract?.id) {
          if (isPointBasedProduct(contract.productTypeId)) {
            await this.$doLoading(async () => {
              await this.$showGqlError(async () => {
                this.contractPoints = await this.$store.dispatch('getContractPointSummary', {
                  contractId: contract.id
                })
              })
            })
          }
        }
      }
    },
    prePaymentAndCancelTvp () {
      this.useTvpAmount = this.prePaymentAndCancelTvp.cancelFee
    }
  },
  methods: {
    async calculate () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          const res = await this.$store.dispatch('calculateCancelPenalty', {
            bookingId: this.booking.id,
            cancelDates: this.cancelStayDates
          })
          this.penalties = res.penalties
        })
      })
    },
    getCancelPenalty (day) {
      return (
        (day.penaltyPrice ?? 0) +
        (day.penaltySpPoint ?? 0) +
        (day.penaltyFpPoint ?? 0) +
        (day.penaltyTvpPoint ?? 0)
      )
    },
    async confirm () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('cancelBooking', {
            bookingId: this.booking.id,
            cancelDates: this.cancelStayDates,
            password: this.password,
            useTvp: this.useTvpAmount ? Number(this.useTvpAmount) : null,
            paymentMethod: this.paymentMethod,
            newPrePayment: this.prePaymentAndCancelTvp.accommodationFee
          })
        })
      })
      this.$emit('complete', {
        totalRefundPoint: this.totalRefundPoint
      })
    }
  }
}
</script>
